import React, { useRef, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import getFluid from '../utils/get-fluid';
import Info from '../components/Products/Info';
import Features from '../components/Products/Features';
import Specifications from '../components/Products/Specifications';
import Compare from '../components/Products/Compare';
import Gallery from '../components/Products/Gallery';
import Difference from '../components/Products/Difference';
import Technology from '../components/Products/Technology';
import Promo from '../components/Products/Promo';
import Reviews from '../components/Products/Reviews';
import FAQ from '../components/Products/FAQ';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar';
import PopUpAdd from '../components/Products/PopUpAdd';
import Shop from '../components/Products/Shop';
import { isEvaLightPlus } from '../utils/slug-checks';
import pagesInfo from '../domain/pages-info';

const promo = graphql`
  {
    right: file(relativePath: { eq: "product-page/evalight/promo-right.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    left: file(relativePath: { eq: "product-page/evalight/promo-left.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
    map1: file(relativePath: { eq: "product-page/evalight/map1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    map2: file(relativePath: { eq: "product-page/evalight/map2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    image1: file(
      relativePath: { eq: "product-page/evalight/gallery/image-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    image2: file(
      relativePath: { eq: "product-page/evalight/gallery/image-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    image3: file(
      relativePath: { eq: "product-page/evalight/gallery/image-3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    image4: file(
      relativePath: { eq: "product-page/evalight/gallery/image-4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    image5: file(
      relativePath: { eq: "product-page/evalight/gallery/image-5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle1: file(
      relativePath: { eq: "product-page/evalight/gallery/lifestyle1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle2: file(
      relativePath: { eq: "product-page/evalight/gallery/lifestyle2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle3: file(
      relativePath: { eq: "product-page/evalight/gallery/lifestyle3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle4: file(
      relativePath: { eq: "product-page/evalight/gallery/lifestyle4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle5: file(
      relativePath: { eq: "product-page/evalight/gallery/lifestyle5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle6: file(
      relativePath: { eq: "product-page/evalight/gallery/lifestyle6.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    vpreview: file(
      relativePath: { eq: "product-page/evalight/gallery/video.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evachill: file(relativePath: { eq: "product-page/evachill.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evasmart: file(relativePath: { eq: "product-page/evasmart.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evalight: file(relativePath: { eq: "product-page/evalight.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    aircon: file(relativePath: { eq: "product-page/aircon.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    fan: file(relativePath: { eq: "product-page/fan.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    technology: file(relativePath: { eq: "product-page/technology.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    white01: file(
      relativePath: { eq: "product-page/evalight/gallery/white/01.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    white02: file(
      relativePath: { eq: "product-page/evalight/gallery/white/02.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    white03: file(
      relativePath: { eq: "product-page/evalight/gallery/white/03.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    white04: file(
      relativePath: { eq: "product-page/evalight/gallery/white/04.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    white05: file(
      relativePath: { eq: "product-page/evalight/gallery/white/05.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    black01: file(
      relativePath: { eq: "product-page/evalight/gallery/black/01.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    black02: file(
      relativePath: { eq: "product-page/evalight/gallery/black/02.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    black03: file(
      relativePath: { eq: "product-page/evalight/gallery/black/03.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    black04: file(
      relativePath: { eq: "product-page/evalight/gallery/black/04.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    black05: file(
      relativePath: { eq: "product-page/evalight/gallery/black/05.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    blue01: file(
      relativePath: { eq: "product-page/evalight/gallery/blue/01.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    blue02: file(
      relativePath: { eq: "product-page/evalight/gallery/blue/02.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    blue03: file(
      relativePath: { eq: "product-page/evalight/gallery/blue/03.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    blue04: file(
      relativePath: { eq: "product-page/evalight/gallery/blue/04.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;

const compare = [
  {
    title: 'evaCHILL',
    subtitle: 'EV-500',
  },
  {
    title: 'evaLIGHTplus',
    subtitle: 'EV-1500',
  },
  {
    title: 'evaSMART',
    subtitle: 'EV-3000',
  },
];

const info = pagesInfo.evalight;

export default function Evalight() {
  const infoRef = useRef(null);
  const [selected, setData] = useState({});

  return (
    <StaticQuery
      query={promo}
      render={(data) => (
        <main>
          <NavBar />
          <Info
            data={info}
            productName={info.title}
            fluids={
              new Map([
                [
                  'common',
                  [
                    'lifestyle1',
                    'lifestyle2',
                    'lifestyle3',
                    'lifestyle4',
                    'lifestyle5',
                    'lifestyle6',
                  ].map((key) => getFluid(data, key)),
                ],
                [
                  'white',
                  ['white01', 'white02', 'white03', 'white04', 'white05'].map(
                    (key) => getFluid(data, key)
                  ),
                ],
                [
                  'black',
                  ['black01', 'black02', 'black03', 'black04', 'black05'].map(
                    (key) => getFluid(data, key)
                  ),
                ],
                [
                  'blue',
                  ['blue01', 'blue02', 'blue03', 'blue04'].map((key) =>
                    getFluid(data, key)
                  ),
                ],
              ])
            }
            youtube={{
              url: 'https://www.youtube.com/embed/MKliAK1Mks8',
              aspectRatio: 16 / 9,
              fluid: getFluid(data, 'image1'),
              title:
                'evaLIGHT - cool device to shape your comfort zone. YouTube video.',
            }}
            ref={infoRef}
            onChange={setData}
          />
          <Features type="evalight" />
          <Specifications
            type="evalight"
            images={[
              data.map1.childImageSharp.gatsbyImageData,
              data.map2.childImageSharp.gatsbyImageData,
            ]}
          />
          <Compare
            header={compare}
            images={[
              data.evachill.childImageSharp.gatsbyImageData,
              data.evalight.childImageSharp.gatsbyImageData,
              data.evasmart.childImageSharp.gatsbyImageData,
            ]}
          />
          <Gallery
            images={[
              data.image1.childImageSharp.gatsbyImageData,
              data.image2.childImageSharp.gatsbyImageData,
              data.image3.childImageSharp.gatsbyImageData,
              data.image4.childImageSharp.gatsbyImageData,
              data.image5.childImageSharp.gatsbyImageData,
            ]}
          />
          <Difference
            info={info}
            images={[
              data.evalight.childImageSharp.gatsbyImageData,
              data.aircon.childImageSharp.gatsbyImageData,
              data.fan.childImageSharp.gatsbyImageData,
            ]}
          />
          <Promo
            isRight
            image={data.right.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="evalight.promo.right.title" />}
            text={<FormattedMessage id="evalight.promo.right.text" />}
          />
          <Technology
            image={data.technology.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="technology.title" />}
            text={<FormattedMessage id="technology.text" />}
            link="technology"
          />
          <Promo
            image={data.left.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="evalight.promo.left.title" />}
            text={<FormattedMessage id="evalight.promo.left.text" />}
          />
          <Shop isRequiredProduct={isEvaLightPlus} />
          <Reviews data={info} />
          <FAQ />
          <PopUpAdd data={selected} targetRef={infoRef} />
          <Footer />
        </main>
      )}
    />
  );
}
